import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"appconfig",
        "id":"appconfig_id",
        "subidname":"appconfig_key",
        "options":["edit","insert","delete"],
        "nameSingle":"config",
        "nameMultiple":"configs",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field2":{
                "name":"Key",
                "field":"appconfig_key",
                "type":"TextInput",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"Value",
                "field":"appconfig_value",
                "type":"TextInput",
                "required":false,
                "list":true
            }
        }
    }
}